<script>


import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import { useAgentStore } from '../../stores/agent';
import chips from '../playerUI/chips.vue';


export default {

  components: { chips },



  data() {

    return {

      domain: window.location.host,
      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),
      agentStore: useAgentStore(),
      referralLink: `https://${window.location.host}/register/${useAccountStore().username}`









    };


  },

  computed: {




  },
  mounted() {

this.accountStore.calculateCommissionToday()
// this.accountStore.computeChipsandCommission()




}



}


</script>


<template>
  <div class="card text-center" style="min-height:460px; max-height: 300px;">
    <div class="card-body">
     
      <div>
        <div class="mb-1"><i class="mdi mdi-account-circle text-primary h1"></i></div>
        <div class="">
          <h5>{{ accountStore.username}}</h5>
          <p class="text-muted mb-1">{{ accountStore.email }}</p>
          <p class="text-muted mb-0">{{ accountStore.cellphoneNumber }}</p>
        </div>
      </div>
    </div>
    <div class="card-body border-top">
      <div class="row d-flex justify-content-center">
        <div class="col-4" v-if="accountStore.role!='superadmin'">
          <div>
            <p class="card-title mb-2 text-warning" >Wallet </p>
            <h5 class=" card-title text-warning mb-0">    <i class="fa fa-solid fa-coins font-size-13"></i>  {{ Number(accountStore.chips).toLocaleString() }}</h5>
          </div>
       
        </div>


          <div class="col-7"  v-if="accountStore.commission > 0">
        <div>
          <p class="card-title text-success"> Commission ({{ accountStore.commission }}%)</p>
          <h5 class=" card-title text-success mb-0">    <i class="mdi mdi-sack-percent  font-size-13"></i>  {{ Number(accountStore.commissionPoints).toLocaleString() }}  </h5>
        </div>
      </div>

           
    </div>
    </div>
   
       
   
    <div class="card-body border-top"  v-if="accountStore.commission > 0 && accountStore.commission < 100">
      <div class="row text-center">
        <div>
          <p class="fw-medium mb-2 text-primary"> <i class="bx bx-link font-size-15 text-primary"></i> Referral Link </p>

         
          <button v-clipboard="referralLink" class="btn btn-primary" @click="agentStore.linkCopied()"><em>{{ referralLink }}</em></button>
        </div>
       
      </div>
    </div>
    <div class="card-body border-top" v-if="accountStore.role == 'player'">
      <div class="text-center"><button @click="accountStore.requestCashInOut('cash-in')" class="btn btn-outline-success me-2 w-md">Cash-In</button>
        
        <button @click="accountStore.requestCashInOut('cash-out')"  class="btn btn-outline-danger me-2 w-md">Cash-Out</button></div>
    </div>
  </div>





</template>