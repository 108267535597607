
<script>

import { SimpleBar } from 'simplebar-vue3';
import axios from 'axios';
import { date } from 'quasar';
import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import moment from "moment/moment";


export default {

  components: { SimpleBar },



  data() {

    return {


      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),
      transactions: [],

      dateToday: moment().format('YYYY-MM-DD'),
      searchDate: moment().format('YYYY-MM-DD'),





    };


  },

  computed: {

    filteredList() {

      return this.playerStore.downlines.filter(username => {
        return username.toLowerCase().includes(this.username.toLowerCase())

      })



    }


  },

  mounted() {

    this.accountStore.getTransactions(this.dateToday, "allTypes")




  }

}


</script>



<template>



  <div class="card" style="min-height:460px">



    <div class="card-body">
      <h4 class="card-title mb-3">Quick View</h4>
      <!-- <input type="text" class="form-control mb-2" v-model="username" placeholder="Search transaction"
                  aria-label="Recipient's username" required> -->
      <b-tabs pills nav-class="bg-light rounded" content-class="mt-3">


        <b-tab title="Today"  @click="accountStore.getTransactions(dateToday, 'allTypes')">
          <b-card-text>
            <SimpleBar style="max-height: 310px">
              <table class="table table-centered align-middle font-size-14">
                
                <p class="text-center" v-if="accountStore.transactions.count == 0">No Transaction today.</p>
                <tbody v-for="transaction of accountStore.transactions.results" class="text-center text-dark">


                  <tr>
                    <td class="text-primary">{{ transaction.type }}</td>
                    <!-- <td v-if="transaction.type == 'CO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-cash-register font-size-22"></i> </td>'
                        '
                        

                    <td v-if="transaction.type == 'PR'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-up font-size-22"></i> </td>

                    <td v-if="transaction.type == 'LO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-angle-double-down font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVW'" class="text-success" style="width: 50px"><i
                        class="dripicons-trophy font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVC'" class="text-success" style="width: 50px"><i
                        class="mdi mdi-sack-percent font-size-22"></i> </td>
                    <td v-if="transaction.type == 'S'" class="text-warning" style="width: 50px"><i
                        class="fas fa-angle-double-left font-size-22"></i> </td>
                    <td v-if="transaction.type == 'R'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-right font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVR'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-replay font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVB'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-poker-chip font-size-22"></i> </td> -->
                      
                    <td class="text-warning">{{ Number(transaction.amount).toLocaleString() }} </td>
                    <td class="text-info" v-if="transaction.type == 'S'">{{ transaction.transactionTo }}</td>
                    <td class="text-info" v-else>{{ transaction.initiator }}
                    </td>
                  
                    <td class="text-pink">{{ playerStore.formatDate(transaction.created_at) }}</td>
 



                  </tr>
                </tbody>
              </table>
            </SimpleBar>
            <h5 class="text-success mt-3 float-end">comm: {{Number( accountStore.commissionToday ).toLocaleString()}}  <i class="mdi mdi-sack-percent  font-size-13"></i> </h5>
          </b-card-text>
        </b-tab>
        <b-tab title="Comms"  @click="accountStore.getTransactions('allDates', 'EVC')">
          <b-card-text>
            <SimpleBar style="max-height: 310px">
              <table class="table table-centered align-middle font-size-14">
                
                <p class="text-center" v-if="accountStore.transactions.count == 0">No Transaction today.</p>
                <tbody v-for="transaction of accountStore.transactions.results" class="text-center text-dark">


                  <tr>
                   
                    <!-- <td v-if="transaction.type == 'CO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-cash-register font-size-22"></i> </td>'
                        '
                        

                    <td v-if="transaction.type == 'PR'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-up font-size-22"></i> </td>

                    <td v-if="transaction.type == 'LO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-angle-double-down font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVW'" class="text-success" style="width: 50px"><i
                        class="dripicons-trophy font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVC'" class="text-success" style="width: 50px"><i
                        class="mdi mdi-sack-percent font-size-22"></i> </td>
                    <td v-if="transaction.type == 'S'" class="text-warning" style="width: 50px"><i
                        class="fas fa-angle-double-left font-size-22"></i> </td>
                    <td v-if="transaction.type == 'R'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-right font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVR'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-replay font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVB'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-poker-chip font-size-22"></i> </td> -->

                    <td class="text-success">{{ Number(transaction.amount).toLocaleString() }} </td>
                    <td class="text-info" v-if="transaction.type == 'S'">{{ transaction.transactionTo }}</td>
                    <td class="text-info" v-if="transaction.type != 'S'">{{ transaction.initiator }}
                    </td>
                  
                    <td class="text-pink">{{ playerStore.formatDate(transaction.created_at) }}</td>
 



                  </tr>
                </tbody>
              </table>
            </SimpleBar>
          </b-card-text>
        </b-tab>


        <b-tab title="All"
          @click="accountStore.getTransactions('allDates', 'allTypes')">
          <b-card-text>
            <SimpleBar style="max-height: 310px">
              <table class="table table-centered align-middle font-size-14">

                <tbody v-for="transaction of accountStore.transactions.results" class="text-center text-dark">


                  <tr>

                    <td class="text-primary">{{ transaction.type }}</td>

                    <!-- <td v-if="transaction.type == 'CO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-cash-register font-size-22"></i> </td>

                    <td v-if="transaction.type == 'PR'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-up font-size-22"></i> </td>

                    <td v-if="transaction.type == 'LO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-angle-double-down font-size-22"></i> </td>

                    <td v-if="transaction.type == 'EVW'" class="text-success" style="width: 50px"><i
                        class="dripicons-trophy font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVC'" class="text-success" style="width: 50px"><i
                        class="mdi mdi-sack-percent font-size-22"></i> </td>
                    <td v-if="transaction.type == 'S'" class="text-warning" style="width: 50px"><i
                        class="fas fa-angle-double-left font-size-22"></i> </td>
                    <td v-if="transaction.type == 'R'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-right font-size-22"></i> </td>

                    <td v-if="transaction.type == 'EVR'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-replay font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVB'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-poker-chip font-size-22"></i> </td> -->

                    <td class="text-warning">{{ Number(transaction.amount).toLocaleString() }} </td>
                    <td class="text-info" v-if="transaction.type == 'S'">{{ transaction.transactionTo }}</td>
                    <td class="text-info" v-if="transaction.type != 'S'">{{ transaction.initiator }}
                    </td>
                    <td class="text-pink">{{ playerStore.formatDate(transaction.created_at) }}</td>




                  </tr>
                </tbody>
              </table>
            </SimpleBar>
          </b-card-text>
        </b-tab>
        <b-tab title="Custom">
          <div class="input-group mb-3 input-group-sm">
            <label class="input-group-text">Date</label>
            <input type="date" v-model="searchDate" data-provide="datepicker" class="form-control" @change="accountStore.searchTransactions(searchDate, 'allTypes')"/>
            <!-- <button class="btn btn-info" type="button" @click="accountStore.searchTransactions(searchDate, 'allTypes')">Search</button> -->
          </div>

          <b-card-text>
            
            <SimpleBar style="max-height: 280px">
              <table class="table table-centered align-middle font-size-14"  v-if="accountStore.transactionSearch.count != 0">


                
               
                <tbody v-for="transaction of accountStore.transactionSearch.results" class="text-center text-dark">


                  <tr>

                    <td class="">{{ transaction.type }}</td>

                    <!-- <td v-if="transaction.type == 'PR'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-up font-size-22"></i> </td>

                    <td v-if="transaction.type == 'LO'" class="text-danger" style="width: 50px"><i
                        class="fas fa-angle-double-down font-size-22"></i> </td>

                    <td v-if="transaction.type == 'EVW'" class="text-success" style="width: 50px"><i
                        class="dripicons-trophy font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVC'" class="text-success" style="width: 50px"><i
                        class="mdi mdi-sack-percent font-size-22"></i> </td>
                    <td v-if="transaction.type == 'S'" class="text-warning" style="width: 50px"><i
                        class="fas fa-angle-double-left font-size-22"></i> </td>
                    <td v-if="transaction.type == 'R'" class="text-success" style="width: 50px"><i
                        class="fas fa-angle-double-right font-size-22"></i> </td>

                    <td v-if="transaction.type == 'EVR'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-replay font-size-22"></i> </td>
                    <td v-if="transaction.type == 'EVB'" class="text-warning" style="width: 50px"><i
                        class="mdi mdi-poker-chip font-size-22"></i> </td>

                    <td class="text-warning">{{ Number(transaction.amount).toLocaleString() }} </td>
                    <td class="text-info" v-if="transaction.type == 'S'">{{ transaction.transactionTo }}</td>
                    <td class="text-info" v-if="transaction.type != 'S'">{{ transaction.initiator }}
                    </td>
                    <td class="text-pink">{{ playerStore.formatDate(transaction.created_at) }}</td> -->



                  </tr>
                </tbody>
              </table>

              <p v-else class="text-center" >No Transaction on {{ searchDate }}.</p>
            </SimpleBar>
          </b-card-text>

        </b-tab>
        <!-- <b-tab title="Sent" v-if="accountStore.commission > 0">
                <b-card-text>
                  <SimpleBar  style="max-height: 310px">
                    <table
                      class="table table-centered  align-middle"
                    >
                      <tbody v-for="transaction of playerStore.transactions.sent.results" class="text-center text-dark">
                      
                         
                  <tr>
                        <td class="text-warning" style="width: 50px"><i class="fas fa-angle-double-right font-size-20"></i> </td>
                   <td class="text-success">{{ Number(transaction.amount).toLocaleString() }} </td>
                   <td class="text-info">{{ transaction.transactionTo }}</td>
                   <td class="text-pink">{{playerStore.formatDate(transaction.created_at)}}</td>
                  </tr>
                      
                      </tbody>
                    </table>
                  </SimpleBar>
                </b-card-text>
              </b-tab> -->
        <!-- <b-tab title="Received">
                <b-card-text>
                  <SimpleBar  style="max-height: 310px">
                    <table
                      class="table table-centered align-middle"
                    >
                      <tbody v-for="transaction of playerStore.transactions.received.results" class="text-center text-dark">
                      
                         
                  <tr>
                   <td class="text-success" style="width: 50px"><i class="fas fa-angle-double-left font-size-22"></i> </td>
                   <td class="text-success">{{ Number(transaction.amount).toLocaleString() }} </td>
                   <td class="text-info">{{ transaction.initiator }}</td>
                   <td class="text-pink">{{playerStore.formatDate(transaction.created_at)}}</td>
                  </tr>
                      </tbody>
                    </table>
                  </SimpleBar>
                </b-card-text>
              </b-tab>

            -->













      </b-tabs>
    </div>
  </div>


</template>



