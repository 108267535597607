<script>

import { SimpleBar } from 'simplebar-vue3';
import axios from 'axios';
import { useAccountStore } from '/src/stores/account'
import {usePlayerStore} from '/src/stores/player'
import { ref, computed, onMounted } from 'vue';



export default {

  setup(){

    const playerStore = usePlayerStore()
    const accountStore = useAccountStore()









  

  return{
playerStore,
accountStore,


  }

  }




  }



</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Chips Management</h4>
      <b-tabs pills nav-class="bg-light rounded" content-class="mt-4">
<div v-if="accountStore.role!='admin'">

  <b-tab title="Buy Chips" active  >
          <b-card-text>
            <div class="float-end ms-2">
              <h5 class="font-size-14">
                <i class="fa fa-solid fa-coins text-warning font-size-16 align-middle me-1"></i>
                {{ accountStore.chips }}
              </h5>
            </div>
            <h5 class="font-size-14 mb-4">Balance</h5>

            <div>
              <div class="form-group mb-3">
                <label>Payment method :</label>
                <select class="form-select">
                  <option>G-Cash</option>
                  <option>Paypal</option>
                </select>
              </div>

              <div>
                <label>Details :</label>
                <div class="input-group mb-3">
                  <label class="input-group-text">Amount</label>

                  <input type="number" class="form-control" />
                </div>
                <div class="input-group mb-3">
                  <label class="input-group-text">Notes:</label>

                  <input type="text" class="form-control" />
                </div>
                <div class="input-group mb-3">
                  
                <input class="form-control" type="file" id="formFile" />

                    </div>

              </div>

              <div class="text-center">
                <button type="button" class="btn btn-success w-md">
                  Proceed
                </button>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Cash-Out">
          <b-card-text>
            <div class="float-end ms-2">
              <h5 class="font-size-14">
                <i class="fa fa-solid fa-coins text-warning font-size-16 align-middle me-1"></i>
                {{ accountStore.chips }}
              </h5>
            </div>
            <h5 class="font-size-14 mb-4">Balance</h5>

            <div>
              <div class="form-group mb-3">
                <label>Payment method :</label>
                <select class="form-select">
                  <option>G-Cash</option>
                  <option>Paypal</option>
                </select>
              </div>

              <div>
                <label>Details :</label>
                <div class="input-group mb-3">
                  <label class="input-group-text">Amount</label>

                  <input type="number" class="form-control" />
                </div>
                <div class="input-group mb-3">
                  <label class="input-group-text">Notes:</label>

                  <input type="text" class="form-control" />
                </div>



              </div>

              <div class="text-center">
                <button type="button" class="btn btn-danger w-md">
                  Proceed
                </button>
              </div>
            </div>
          </b-card-text>
        </b-tab>
</div>
       
        <b-tab title="Send Chips">
          <b-card-text>
            <div class="float-end ms-2">
              <h5 class="font-size-14">
                <i class="fa fa-solid fa-coins text-warning font-size-16 align-middle me-1"></i>
                {{ accountStore.chips }}
              </h5>
            </div>
            <h5 class="font-size-14 mb-4">Balance</h5>

            <b-form @submit.prevent="accountStore.sendChips(username, chips)">
              <div class="form-group mb-3">
                <label>Username:</label>
                <input type="text" class="form-control" v-model="username" placeholder="Search ..."
                  aria-label="Recipient's username" required>
    
                <b-dropdown v-if="username"></b-dropdown>
                <ul class="" v-if="searchOn">
                  <li v-for="username in filteredList" :key="username">
                    <b-button @click="setSearch(username)">   {{ username }}</b-button>
                
                  </li>
                </ul>
              </div>

              <div>
                <label>Details :</label>




                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" >Chips</label>
                  </div>
                  <input type="number" v-model="chips" class="form-control" required/>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text">Notes</label>
                  </div>
                  <input type="text" class="form-control" />
                </div>
              </div>

              <div class="text-center">
                <button type="submit" class="btn btn-warning w-md" >
                  Send
                </button>
              </div>
            </b-form>
          </b-card-text>
        </b-tab>
      
      </b-tabs>
    </div>
  </div>

</template>