<script>

import { SimpleBar } from 'simplebar-vue3';
import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import { useAdminStore } from '../../stores/admin';
import moment from "moment/moment";

import { useAgentStore } from '../../stores/agent';


export default {
  data() {
    return {
      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),
      adminStore: useAdminStore(),
      agentStore: useAgentStore(),
      transactions: [],

      dateToday: moment().format('YYYY-MM-DD'),
      searchDate: moment().format('YYYY-MM-DD'),

      chartData: {

        series: [{
          name: 'Profit',
          data: []
        }, {
          name: 'Loss',
          data: []
        },
        ],

      },
      earnings: [

      ],
      chartOptions: {
        xaxis: {
          categories: ['Sabong'],
        },
        chart: {
          height: 320,
          type: "bar",
          toolbar: "false",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 8,
            opacity: 0.2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#556ee6"],
        stroke: {
          curve: "smooth",
          width: 3,
        },
      },
    };
  },
  mounted() {

    // this.accountStore.getTransactions(this.dateToday, "allTypes")

    // this.adminStore.getProfit(this.dateToday)

    this.adminStore.summaryReports(this.dateToday)








  },

  method: {
    hello(event) { console.log(event.target.value) },

    updateChartData() {

      console.log("hello")


      this.chartData.series =

        [{
          name: 'Profit',
          data: [10000]
        },
        {
          name: 'Loss',
          data: []
        }
        ]






    }



  }

}
</script>

<template>



  <div class="card no-footer" style="min-height:460px">
    <div class="card-body">
      <h4 class="card-title mb-4">Reports</h4>

      <b-tabs pills nav-class="bg-light rounded" content-class="mt-3">



        <b-tab title="Daily">




          <div class="">
            <div class="input-group">
              <label class="input-group-text">Date</label>
              <input @change="adminStore.summaryReports(searchDate)" type="date" v-model="searchDate"
                data-provide="datepicker" class="form-control" />
              <!-- <button class="btn btn-info" type="button" >Search</button> -->
            </div>
          </div>







          <!-- Table -->

          <div class="table-responsive mt-3 mb-0" v-if="adminStore.summaryReport.data.count != 0">

            <table class="table table-centered align-middle font-size-14">
              <thead class="">
                <h5 class="card-title">Bets</h5>

                <tr>

                  <td class="text-danger">MERON</td>
                  <td class="text-primary">WALA</td>
                  <td class="text-success">DRAW</td>
                  <td class="text-dark">TOTAL</td>

                </tr>
              </thead>
              <tbody class="">
                <tr>
                  <td class="text-danger">{{ Number(adminStore.summaryReport.meron).toLocaleString() }}</td>
                  <td class="text-primary">{{ Number(adminStore.summaryReport.wala).toLocaleString() }}</td>
                  <td class="text-success">{{ Number(adminStore.summaryReport.draw).toLocaleString() }}</td>
                  <td class="text-dark">{{
                    Number(adminStore.summaryReport.draw + adminStore.summaryReport.meron + adminStore.summaryReport.wala).toLocaleString()
                  }}</td>


                </tr>

              </tbody>
            </table>
            <h5 class="card-title">Commissions</h5>
            <table class="table table-centered align-middle font-size-14">

              <tbody class="text-dark">
                <tr>
                  <td>Site</td>
                  <td class="">{{ Number(adminStore.summaryReport.siteCommission).toLocaleString() }}</td>



                </tr>
                <tr>
                  <td>Agents</td>
                  <td class="">{{ Number(adminStore.summaryReport.agentCommissions).toLocaleString() }}</td>



                </tr>

              </tbody>
            </table>




          </div>
          <div class="text-center" v-else>

            No Data found

          </div>



        </b-tab>


      </b-tabs>








    </div>

  </div>




</template>