
<script>
import axios from 'axios';

import useVuelidate from "@vuelidate/core";
import { useEventStore } from "/src/stores/events";
import { useAdminStore } from '../../stores/admin';
import { usePlayerStore } from '../../stores/player';
import { SimpleBar } from "simplebar-vue3"




import {
  required,
  helpers,

} from "@vuelidate/validators";


export default {
  setup() {

    return { v$: useVuelidate() };
  },

  components: {SimpleBar},

  data() {

    return {
      eventDetails: false,
      eventStore: useEventStore(),
      adminStore: useAdminStore(),
      playerStore: usePlayerStore(),

      gameType: 'Sabong',
      eventName: "",
      plasada: "",
      minimumBet: 10,
      eventVideoLink: "",
      submitted: false,


    };






  },

  validations: {
    eventName: {
      required: helpers.withMessage("Please enter Event name.", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },


  methods: {











  }
}

</script>


<template>
  <div class="card" style="min-height: 460px">
    <div class="card-body">
      <h4 class="card-title mb-4">Events Management</h4>
      <b-tabs pills nav-class="bg-light rounded" content-class="mt-4">

        <b-tab title="Create" active>
          <SimpleBar style="max-height: 400px;">
          <b-card-text>
            

            <b-form class="p-2" @submit.prevent="adminStore.createEvent(gameType, eventName, plasada, minimumBet, eventDate, eventVideoLink)">
              <div class="input-group mb-3">
                <label class="input-group-text">Game Type</label>

                <select class="form-control" v-model="gameType">
                  <option>Sabong</option>
                  <option>Color Game</option>
                  <option>Coming Soon</option>
                </select>

                </div>

                <div class="input-group mb-3">
                  <label class="input-group-text">Event Name</label>

                  <input type="text" v-model="eventName" class="form-control" required />
                </div>
                <div class="input-group mb-3">
                  <label class="input-group-text">Plasada</label>

                  <input type="number" min="0"  step="0.50" max="99" v-model="plasada" class="form-control" required />
                </div>
                <div class="input-group mb-3">
                  <label class="input-group-text">Minimum bet</label>

                  <input type="number" min="0"  v-model="minimumBet" class="form-control" required />
                </div>
                <!-- <div class="input-group mb-3">
                  <label class="input-group-text">Date</label>
                  <input type="date" v-model="eventDate" class="form-control" required />
                </div> -->
                <div class="input-group mb-3">
                  <label class="input-group-text">Video Link</label>


                  <input type="url" v-model="eventVideoLink" class="form-control" required />
                </div>



                <div class="mt-3 d-grid">
                  <b-button type="submit" variant="primary" class="btn-block">Create Event</b-button>
                </div>



            </b-form>

         

          </b-card-text>
        </SimpleBar>
        </b-tab>
        <b-tab title="Ongoing">
          <b-card-text>
           
           
              <SimpleBar style="max-height: 200px;">
                <table class="table table-striped align-middle mb-0 table-sm text-center table-responsive">
                 
                  <tbody>


                    <tr v-for="event in adminStore.openEvents">

                      <td
                        class="font-size-13 mb-1 text-info">
                          {{ event.eventName}}
                        
                      </td>

                      <td
                        class="font-size-13 mb-1 text-success">
                          {{ event.gameType}}
                        
                      </td>
                      
                  
                      
                    

                      <td>
                       
                          <button class="btn btn-outline-danger" type="button" @click="adminStore.closeEvent(event.eventId, event.gameType)"> End</button>
                      
                      </td>
                      <td>
                       
                       <button class="btn btn-outline-info" type="button" @click="adminStore.editEvent(event)"> Edit</button>
                   
                   </td>



                  



                    </tr>
                  </tbody>
                </table>
          
        
   
          </SimpleBar>
          


          </b-card-text>
        </b-tab>
        <b-tab title="Closed">
          <b-card-text>

           
              <SimpleBar style="max-height: 350px;">
                <table class="table table-striped align-middle mb-0 table-sm table-responsive text-center">
                 
                  <tbody>


                    <tr v-for="event in adminStore.closedEvents">

                      <td
                        class="font-size-13 mb-1 text-info">
                          {{ event.eventName}}
                        
                      </td>
                    

                      <td
                        class="font-size-13 mb-1 text-success">
                          {{ event.gameType}}
                        
                      </td>

                      <td   class="font-size-13 mb-1 text-warning">
                        {{playerStore.formatDate(event.eventDateCreated_at)}}
                      
                      
                      </td>
                      
                  
                      
                    

                    
                      <td>
                       
                       <button class="btn btn-outline-info" type="button" @click="adminStore.eventDetails(event)">Details</button>
                   
                   </td>



                  



                    </tr>
                  </tbody>
                </table>
              </SimpleBar>
        
          




          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>

</template>