<script>
import axios from 'axios';
import { useEventStore } from "/src/stores/events";


import { SimpleBar } from 'simplebar-vue3';
import moment from 'moment';


export default {
components: {SimpleBar},


    data() {

return {
    eventStore: useEventStore(),
    schedDate: ''
 


};


    },
    mounted(){



        this.eventStore.getPublicEvents()


    

    },
    computed: {

        


    },

    methods: {
        formatDate(date){
            return moment(date).format('MMMM DD YYYY')
        }
      
  



    }

  
 
}
</script>




<template>
<h2  class="text-center" v-if="!eventStore.publicEvents.length">No Events yet.</h2>
<SimpleBar style="max-height:460px;" >

<b-card v-for="event in eventStore.publicEvents" header-class="bg-transparent border-primary" class="border border-primary no-gutters ">
<center >

<router-link :to= '"/event/" + event.gameType + "/" + event.eventId'  >
    <div>
        <div class="card mb-3">
            
                <b-row class="align-items-center text-info">
                    <div class="col-5">
                        <b-card-img class="rounded-circle  avatar-lg" width="900px" v-if="event.gameType== 'sabong'" :src="require('@/assets/images/sabongfight.png')"></b-card-img>
                        <b-card-img class="rounded-circle  avatar-lg" width="900px" v-if="event.gameType== 'color-game'" :src="require('@/assets/images/cgk.png')"></b-card-img>
                    </div>
                    <div class="col-6">
                        <b-card-title>
                    <h5 class="card-title text-info">{{event.eventName}}</h5>
                </b-card-title>
                            <b-card-text class="text-info">
                                {{ formatDate(event.eventSchedDate) }}
                              
                            </b-card-text>
                            Plasada: {{event.plasada}}%
                                
                    </div>
                </b-row>
            
        </div>
        </div>  
    </router-link>


</center>
</b-card>

</SimpleBar>
</template>
