<script>

import { SimpleBar } from 'simplebar-vue3';
import axios from 'axios';
import { date } from 'quasar';
import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import { useAdminStore } from '../../stores/admin';
import { useAgentStore } from '../../stores/agent';


export default {

  components: { SimpleBar },



  data() {

    return {


      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),
      adminStore: useAdminStore(),
      agentStore: useAgentStore(),

      username: '',
      referral: '',
      date: "All",
      transaction: 'All',
      entries: 10,
      gameType: 'sabong',

      username: '',

      sendchips: 0,

      user: '',
      testModal: false




    };


  },

  computed: {




  },

  mounted() {
   this.adminStore.getAgents()


  }

}


</script>


<template>

  <b-modal v-if="adminStore.userDetails" v-model="testModal" id="modal-center" centered title="User Details"
    title-class="font-18" hide-footer>
    <h5 class="text-dark" v-if="adminStore.userDetails">

      <table class="table table-striped text-dark align-middle table-sm text-center table-responsive font-size-14 ">


        <tbody>



          <tr>
            <th>Email</th>
            <td>{{ adminStore.userDetails.email }}</td>
          </tr>
          <tr>
            <th>Cell#</th>
            <td> {{ adminStore.userDetails.cellphoneNumber }}</td>

          </tr>
          <tr>
            <th>Date joined</th>
            <td>{{ this.playerStore.formatDate(adminStore.userDetails.date_joined) }}</td>
          </tr>
          <tr>
            <th>Last login</th>
            <td>{{ this.playerStore.formatDate(adminStore.userDetails.last_login) }}</td>
          </tr>
          <tr>
            <th>Referred by</th>
            <td><button v-if="adminStore.userDetails.referred_by" class="btn btn-info" type="button"
                @click="adminStore.modalUserDetails(adminStore.userDetails.referred_by)"> {{
                  adminStore.userDetails.referred_by
                }}</button></td>
          </tr>
          <tr>
            <th>Referrals</th>
            <td>
              <p style="float: left" class="mr-1" v-for="referral in adminStore.userDetails.referrals">
                <button class="btn btn-info" type="button" @click="adminStore.modalUserDetails(referral)">
                  {{ referral.username }}</button>
              </p>
            </td>
          </tr>
        </tbody>
      </table>




      <button class="btn btn-info" type="button" @click="adminStore.resetPassword(adminStore.userDetails.username)">
        Reset Password</button>
      <button v-if="adminStore.userDetails.is_active == true" class="btn btn-danger" type="button"
        @click="agentStore.changeUserStatus(adminStore.userDetails, 'deny')"> Deactivate User</button>
      <button v-if="adminStore.userDetails.is_active == false" class="btn btn-success" type="button"
        @click="agentStore.changeUserStatus(adminStore.userDetails, 'allow')"> Activate User</button>




    </h5>
  </b-modal>


  <div class="card no-footer" style="min-height:460px">
    <div class="card-body">
      <h4 class="card-title mb-4">Master List</h4>

      <b-tabs pills nav-class="bg-light rounded" content-class="mt-3">

        <!-- <b-tab title="Pending" @click="adminStore.getPreActiveAccounts">

          <div class="input-group mb-4">

            <input type="text" v-model="username" class="form-control" placeholder="Search username" />
            <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
              :disabled="!username">Search</button>
          </div>





          <b-card-text>

            <div class="">
              <SimpleBar style="max-height: 400px;">
                <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
                  v-if="adminStore.user != 'null'">
                  <thead>
                    <tr class="font-size-13 text-center">
                      <th class="text-info"> Username</th>


                      <th class="text-warning">Referred by</th>
                      <th class="text-success">Allow</th>

                      <th class="text-danger">Deny</th>
                   



                    </tr>
                  </thead>
                  <tbody>


                    <tr v-for="account in adminStore.preActiveAccounts" class="font-size-13 text-center"
                      v-if="adminStore.user == ''">



                      <td>
                        <button class="btn btn-outline-info" type="button"
                          @click="adminStore.modalUserDetails(account); testModal = true"> {{
                            account.username
                          }}</button>

                      </td>







                      <td class="text-warning">

                        {{ account.referred_by }}

                      </td>

                      <td>

                        <button class="btn btn-outline-success" type="button"
                          @click="agentStore.changeUserStatus(account, 'allow')"> Allow </button>


                      </td>


                      <td>

                        <button class="btn btn-outline-danger" type="button"
                          @click="agentStore.changeUserStatus(account, 'deny')"> Deny</button>

                      </td>





                    </tr>

                    <tr v-else>
                      <div>
                        <td>
                          <button class="btn btn-outline-info" type="button"
                            @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                              adminStore.user.username
                            }}</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-success" type="button"
                            @click="accountStore.setCommission(adminStore.user.username)"> {{
                              adminStore.user.commission
                            }} %</button>


                        </td>




                        <td>

                          <button class="btn btn-outline-warning" type="button"
                            @click="accountStore.sendChips(adminStore.user.username)"> Send</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-danger" type="button"
                            @click="accountStore.receiveChips(adminStore.user.username)"> CO</button>

                        </td>



                      </div>


                    </tr>
                  </tbody>
                </table>

                <div class="text-center" v-else>

                  Account not found.

                </div>
              </SimpleBar>
            </div>


          </b-card-text>


        </b-tab> -->
        <b-tab title="Agents" @click="adminStore.getAgents">

          <div class="input-group mb-4">

            <input type="text" v-model="username" class="form-control" placeholder="Search username" />
            <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
              :disabled="!username">Search</button>
          </div>





          <b-card-text>

            <div class="">
              <SimpleBar style="max-height: 400px;">
                <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
                  v-if="adminStore.user != 'null'">
                  <thead>
                    <tr class="font-size-13 text-center">
                      <th class="text-primary"> Username</th>
            <th class="text-warning"> Wallet</th>
            <th class="text-success"> Comm.Points</th>
            <th class="text-success">Commission</th>
            <th class="text-warning">CI</th>
            <th class="text-danger">CO</th>
            <th class="text-pink">CTW</th>
                      <!-- <th class="text-pink">Others</th> -->



                    </tr>
                  </thead>
                  <tbody>


                    <tr v-for="account in adminStore.agents.results" class="font-size-13 text-center"
                      v-if="adminStore.user == ''">



                      
                      <td>
                        <button style="width: 100px;" class="btn btn-outline-primary" type="button"
                          @click="adminStore.modalUserDetails(account); testModal = true"> {{
                            account.username
                          }}</button>

                      </td>

                      <td class="text-warning">
                        {{ Number(account.chips).toLocaleString() }}

                      </td>

                      <td class="text-success">
              {{ Number(account.commissionPoints).toLocaleString() }}

            </td>



                      <td>

                        <button class="btn btn-outline-success" type="button"
                          @click="accountStore.setCommission(account.username)"> {{ account.commission }} %</button>


                      </td>




                      <td>

                        <button class="btn btn-outline-warning" type="button"
                          @click="accountStore.sendChips(account.username)"> CI</button>

                      </td>

                      <td>

                        <button class="btn btn-outline-danger" type="button"
                          @click="accountStore.receiveChips(account.username)"> CO</button>

                      </td>


                      <td>

<button class="btn btn-outline-pink" type="button"
  @click="agentStore.convertToChips(account.username)"> CTW</button>

</td>



                      <!-- <td>
                       <button class="btn btn-outline-pink" type="button">Details</button>
                     </td> -->



                    </tr>



                        <tr v-else>



                      
                      <td>
                        <button style="width: 100px;" class="btn btn-outline-primary" type="button"
                          @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                            adminStore.user.username
                          }}</button>

                      </td>

                      <td class="text-warning">
                        {{ Number(adminStore.user.chips).toLocaleString() }}

                      </td>

                      <td class="text-success">
              {{ Number(adminStore.user.commissionPoints).toLocaleString() }}

            </td>



                      <td>

                        <button class="btn btn-outline-success" type="button"
                          @click="accountStore.setCommission(adminStore.user.username)"> {{ adminStore.user.commission }} %</button>


                      </td>




                      <td>

                        <button class="btn btn-outline-warning" type="button"
                          @click="accountStore.sendChips(adminStore.user.username)"> CI</button>

                      </td>

                      <td>

                        <button class="btn btn-outline-danger" type="button"
                          @click="accountStore.receiveChips(adminStore.user.username)"> CO</button>

                      </td>


                      <td>

<button class="btn btn-outline-pink" type="button"
  @click="agentStore.convertToChips(adminStore.user.username)"> CTW</button>

</td>



                      <!-- <td>
                       <button class="btn btn-outline-pink" type="button">Details</button>
                     </td> -->



                    </tr>

                   
                  </tbody>
                </table>

                <div class="text-center" v-else>

                  Account not found.

                </div>
              </SimpleBar>
            </div>
            <!-- <div class="table-responsive ">
             <table class="table align-middle table-nowrap mb-0">
               <th>test</th>
               <th>test</th>
               <tbody>


                 <tr v-for="referral in accountStore.referrals">

                   <td class="">
                     <h5 class="font-size-13 mb-1">
                       {{ referral.username }}
                     </h5>


                   </td>
                   <td>    <div class="input-group"><input type="number" min="1" class="form-control" 
                        >
                        <button class="btn btn-outline-warning"
                         type="button" > Send Chips </button></div></td>
                   <td>

                 

                  
                     <button class="btn btn-outline-info" type="button">Details</button>
                   </td>
                 </tr>




               </tbody>
             </table>
           </div> -->

          </b-card-text>


        </b-tab>

        <b-tab title="Players" @click="adminStore.getPlayers">

<div class="input-group mb-4">

  <input type="text" v-model="username" class="form-control" placeholder="Search username" />
  <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
    :disabled="!username">Search</button>
</div>





<b-card-text>

  <div class="">
    <SimpleBar style="max-height: 400px;">
      <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
        v-if="adminStore.user != 'null'">
        <thead>
          <tr class="font-size-13 text-center">
            <th class="text-primary"> Username</th>
            <th class="text-warning"> Wallet</th>
            <th class="text-success">Commission</th>
            <th class="text-warning">CI</th>
            <th class="text-danger">CO</th>
            <!-- <th class="text-pink">Others</th> -->



          </tr>
        </thead>
        <tbody>


          <tr v-for="account in adminStore.players.results" class="font-size-13 text-center"
            v-if="adminStore.user == ''">



            <td>
              <button style="width: 100px;" class="btn btn-outline-primary" type="button"
                @click="adminStore.modalUserDetails(account); testModal = true"> {{
                  account.username
                }}</button>

            </td>

            <td class="text-warning">
              {{ Number(account.chips).toLocaleString() }}

            </td>



            <td>

              <button class="btn btn-outline-success" type="button"
                @click="accountStore.setCommission(account.username)"> {{ account.commission }} %</button>


            </td>




            <td>

              <button class="btn btn-outline-warning" type="button"
                @click="accountStore.sendChips(account.username)"> Send</button>

            </td>

            <td>

              <button class="btn btn-outline-danger" type="button"
                @click="accountStore.receiveChips(account.username)"> CO</button>

            </td>



            <!-- <td>
             <button class="btn btn-outline-pink" type="button">Details</button>
           </td> -->



          </tr>

          <tr v-else>
            <div>
              <td>
                <button class="btn btn-outline-info" type="button"
                  @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                    adminStore.user.username
                  }}</button>

              </td>

              <td>

                <button class="btn btn-outline-success" type="button"
                  @click="accountStore.setCommission(adminStore.user.username)"> {{
                    adminStore.user.commission
                  }} %</button>


              </td>




              <td>

                <button class="btn btn-outline-warning" type="button"
                  @click="accountStore.sendChips(adminStore.user.username)"> Send</button>

              </td>

              <td>

                <button class="btn btn-outline-danger" type="button"
                  @click="accountStore.receiveChips(adminStore.user.username)"> CO</button>

              </td>



            </div>


          </tr>
        </tbody>
      </table>

      <div class="text-center" v-else>

        Account not found.

      </div>
    </SimpleBar>
  </div>
  <!-- <div class="table-responsive ">
   <table class="table align-middle table-nowrap mb-0">
     <th>test</th>
     <th>test</th>
     <tbody>


       <tr v-for="referral in accountStore.referrals">

         <td class="">
           <h5 class="font-size-13 mb-1">
             {{ referral.username }}
           </h5>


         </td>
         <td>    <div class="input-group"><input type="number" min="1" class="form-control" 
              >
              <button class="btn btn-outline-warning"
               type="button" > Send Chips </button></div></td>
         <td>

       

        
           <button class="btn btn-outline-info" type="button">Details</button>
         </td>
       </tr>




     </tbody>
   </table>
 </div> -->

</b-card-text>


</b-tab>



        <!-- 
<b-tab title="Bets">

  <div class="input-group mb-3 input-group-sm">

    <input type="text" v-model="user" class="form-control" placeholder="Enter username" />
    <button class="btn btn-info" type="button" @click="accountStore.searchReferralHistory(user)"
      :disabled="!user">Search</button>
  </div>



  <b-card-text>

    <SimpleBar style="max-height: 300px">
      <table class="table table-centered align-middle font-size-14"
        v-if="accountStore.referralHistory.results != 0">



        <tbody v-for="transaction of accountStore.referralHistory.results" class="text-center text-dark">


          <tr>






            <td v-if="transaction.transactionType == 'EVB'" class="text-warning" style="width: 50px"><i
                class="mdi mdi-poker-chip font-size-22"></i> </td>

            <td class="text-warning">{{ Number(transaction.amount).toLocaleString() }} </td>
            <td class="text-info" v-if="transaction.transactionType == 'S'">{{ transaction.transactionTo }}</td>
    <td class="text-info" v-if="transaction.transactionType != 'S'">{{ transaction.transactionFrom }}
    </td>
            <td class="text-pink">{{ playerStore.formatDate(transaction.created_at) }}</td>



          </tr>
        </tbody>
      </table>

      <div class="text-center" v-else>

        No Bets found

      </div>

      <div class="text-center" v-if="accountStore.referralHistory == 'none'">

        Account not found

      </div>
    </SimpleBar>
    <ul class="pagination pagination-sm justify-content-end mt-1" role="menubar" aria-disabled="false"
      aria-label="Pagination">
 
      <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark" aria-label="Go to next page" aria-controls="my-table"
          role="menuitem" type="button" tabindex="-1" :disabled="accountStore.referralHistory.previous==null" @click="accountStore.nextReferralHistory(accountStore.referralHistory.previous)">Previous</b-button></li>
      <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark" aria-label="Go to last page" aria-controls="my-table"
          role="menuitem" type="button" tabindex="-1" :disabled="accountStore.referralHistory.next==null" @click="accountStore.nextReferralHistory(accountStore.referralHistory.next)">Next</b-button></li>
    </ul>
  </b-card-text>

</b-tab> -->
        <b-tab title="Transactions">
          <form>
            <div class="row">
              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Username</label>
                  <input type="text" v-model="referral" class="form-control" placeholder="Search" />

                </div>
              </div>
              <div class="col-4">
                <div class="form-group mt-3 mb-0">

                  <label>Date</label>
                  <input type="date" v-model="date" data-provide="datepicker" class="form-control" />

                </div>
              </div>

              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Transaction</label>
                  <select class="form-control" v-model="transaction">
                    <option value="All" selected>All</option>
                    <option value="EVB">Bets</option>
                    <option value="CI">Cash-In</option>
                    <option value="CO">Cash-Out</option>
                    <option value="CTW">Commission to Wallet</option>
                    <option value="EVC">Commission</option>
                    <option value="S">Sent</option>
                    <option value="R">Received</option>
                    <option value="EVW">Win</option>
                    <option value="EVR">Refund</option>
                  </select>
                </div>
              </div>



              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Show # of entries</label>
                  <select class="form-control" v-model="entries">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div class="col-xl col-sm-6 align-self-end">
                <div class="mt-3">
                  <button class="btn btn-primary w-md" type="button"
                    @click="adminStore.searchTransaction(referral, date, transaction, entries)"
                    :disabled="!referral">Search</button>
                </div>
              </div>
            </div>
          </form>

          <!-- Table -->
          <SimpleBar style="max-height: 500px">
            <div class="table-responsive mt-3 mb-0">
              <table class="table table-centered align-middle font-size-14" v-if="adminStore.userHistory.count != 0">
                <thead class="text-center text-dark">

                  <tr>
                    <td>Type</td>
                    <td>Created at</td>
                    <td>Amount</td>
                    
                    <td>By</td>

                  </tr>
                </thead>
                <tbody v-for="transaction of adminStore.userHistory.results" class="text-center text-dark">
                  <tr>
                    
                    <td>{{ transaction.type }}</td>
                    <td class="text-info">{{ playerStore.formatDate(transaction.created_at) }}</td>
                    <td class="text-danger" v-if="transaction.type=='CO'"> - {{ Number(transaction.amount).toLocaleString() }}</td>
                    <td class="text-success" v-else-if="transaction.type=='CI'">  + {{ Number(transaction.amount).toLocaleString() }}</td>
                    <td class="text-warning" v-else>  {{ Number(transaction.amount).toLocaleString() }}</td>
                 
                    <td v-if="transaction.type!='EVC'">{{ transaction.transactionTo }}</td>
                    <td v-else>{{ transaction.initiator }}</td>
                  </tr>

                </tbody>
              </table>

              <div class="text-center" v-else>

                No Data found

              </div>
            </div>
          </SimpleBar>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-sm justify-content-end mt-1" role="menubar" aria-disabled="false"
                  aria-label="Pagination">


                  <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark"
                      aria-label="Go to next page" aria-controls="my-table" role="menuitem" type="button" tabindex="-1"
                      :disabled="agentStore.referralHistory.previous == null"
                      @click="agentStore.nextReferralHistory(agentStore.referralHistory.previous)">Previous</b-button>
                  </li>
                  <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark"
                      aria-label="Go to last page" aria-controls="my-table" role="menuitem" type="button" tabindex="-1"
                      :disabled="agentStore.referralHistory.next == null"
                      @click="agentStore.nextReferralHistory(agentStore.referralHistory.next)">Next</b-button></li>

                </ul>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Bets">
          <form>
            <div class="row">
              <div class="col-3">
                <div class="form-group mt-3 mb-0">
                  <label>Username</label>
                  <input type="text" v-model="referral" class="form-control" placeholder="Search" />

                </div>
              </div>
              <div class="col-3">
                <div class="form-group mt-3 mb-0">

                  <label>Date</label>
                  <input type="date" v-model="date" data-provide="datepicker" class="form-control" />

                </div>
              </div>

           


              <div class="col-3">
                <div class="form-group mt-3 mb-0">
                  <label>Show # of entries</label>
                  <select class="form-control" v-model="entries">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div class="col-xl col-sm-6 align-self-end">
                <div class="mt-3">
                  <button class="btn btn-primary w-md" type="button"
                    @click="adminStore.searchBetHistory(referral, date, entries)"
                    :disabled="!referral">Search</button>
                </div>
              </div>
            </div>
          </form>

          <!-- Table -->
          <SimpleBar style="max-height: 500px">
            <div class="table-responsive mt-3 mb-0">
              <table class="table table-centered align-middle font-size-14"
                v-if="adminStore.betHistory.count != 0">
                <thead class="text-center">

                  <tr>
                    <td>Username</td>

<td>Event Name</td>
<td>Fight</td>
<td>Bet</td>
<td>Choice</td>
<td>Winner</td>
<td>Balance</td>
<td>Created</td>


                  </tr>
                </thead>
                <tbody v-for="transaction of adminStore.betHistory.results" class="text-center">
                  <tr>

<td>{{ transaction.userId }}</td>

<td>{{ transaction.event_name }}</td>
<td>{{ transaction.roundNumber }}</td>
<td>{{ transaction.amount }}</td>
<td class="text-danger" v-if="transaction.choice == 'meron'"> M </td>
<td class="text-primary" v-if="transaction.choice == 'wala'"> W </td>
<td class="text-success" v-if="transaction.choice == 'draw'"> D </td>
<td class="text-danger" v-if="transaction.winner_choice == 'meron'"> M </td>
<td class="text-primary" v-if="transaction.winner_choice == 'wala'"> W </td>
<td class="text-success" v-if="transaction.winner_choice == 'draw'"> D </td>
<td class="text-dark" v-if="transaction.winner_choice == null"> C </td>

<td class="text-success" v-if="transaction.winner_choice == transaction.choice">
  (+{{ transaction.finalBalance - transaction.currentBalance }})
  {{ Number(transaction.finalBalance).toLocaleString() }}</td>




<td class="text-danger" v-else>
  ({{ transaction.finalBalance - transaction.currentBalance }})
  {{ Number(transaction.finalBalance).toLocaleString() }}</td>
<td>{{ playerStore.formatDate(transaction.created_at) }}</td>
</tr>
                </tbody>
              </table>

              <div class="text-center" v-else>

                No Data found

              </div>
            </div>
          </SimpleBar>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-sm justify-content-end mt-1" role="menubar" aria-disabled="false"
                  aria-label="Pagination">


                  <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark"
                      aria-label="Go to next page" aria-controls="my-table" role="menuitem" type="button" tabindex="-1"
                      :disabled="agentStore.referralHistory.previous == null"
                      @click="agentStore.nextReferralHistory(agentStore.referralHistory.previous)">Previous</b-button>
                  </li>
                  <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark"
                      aria-label="Go to last page" aria-controls="my-table" role="menuitem" type="button" tabindex="-1"
                      :disabled="agentStore.referralHistory.next == null"
                      @click="agentStore.nextReferralHistory(agentStore.referralHistory.next)">Next</b-button></li>

                </ul>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Blocked" @click="adminStore.getBlockedAccounts">

          <div class="input-group mb-4">

            <input type="text" v-model="username" class="form-control" placeholder="Search username" />
            <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
              :disabled="!username">Search</button>
          </div>





          <b-card-text>

            <div class="">
              <SimpleBar style="max-height: 400px;">
                <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
                  v-if="adminStore.user != 'null'">
                  <thead>
                    <tr class="font-size-13 text-center">
                      <th class="text-info"> Username</th>



                      <th class="text-warning">Referred by</th>
                      <th class="text-success">Allow</th>

                      <!-- <th class="text-pink">Others</th> -->



                    </tr>
                  </thead>
                  <tbody>


                    <tr v-for="account in adminStore.blockedAccounts" class="font-size-13 text-center"
                      v-if="adminStore.user == ''">



                      <td>
                        <button class="btn btn-outline-info" type="button"
                          @click="adminStore.modalUserDetails(account); testModal = true"> {{
                            account.username
                          }}</button>

                      </td>



                      <td class="text-warning">

                        {{ account.referred_by }}

                      </td>

                      <td>
                        <button class="btn btn-outline-success" type="button"
                          @click="agentStore.changeUserStatus(account, 'allow')"> Allow </button>


                      </td>








                      <!-- <td>
                       <button class="btn btn-outline-pink" type="button">Details</button>
                     </td> -->


                    </tr>

                    <tr v-else>
                      <div>
                        <td>
                          <button class="btn btn-outline-info" type="button"
                            @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                              adminStore.user.username
                            }}</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-success" type="button"
                            @click="accountStore.setCommission(adminStore.user.username)"> {{
                              adminStore.user.commission
                            }} %</button>


                        </td>




                        <td>

                          <button class="btn btn-outline-warning" type="button"
                            @click="accountStore.sendChips(adminStore.user.username)"> Send</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-danger" type="button"
                            @click="accountStore.receiveChips(adminStore.user.username)"> CO</button>

                        </td>



                      </div>


                    </tr>
                  </tbody>
                </table>

                <div class="text-center" v-else>

                  Account not found.

                </div>
              </SimpleBar>
            </div>
            <!-- <div class="table-responsive ">
           <table class="table align-middle table-nowrap mb-0">
             <th>test</th>
             <th>test</th>
             <tbody>


               <tr v-for="referral in accountStore.referrals">

                 <td class="">
                   <h5 class="font-size-13 mb-1">
                     {{ referral.username }}
                   </h5>


                 </td>
                 <td>    <div class="input-group"><input type="number" min="1" class="form-control" 
                      >
                      <button class="btn btn-outline-warning"
                       type="button" > Send Chips </button></div></td>
                 <td>

               

                
                   <button class="btn btn-outline-info" type="button">Details</button>
                 </td>
               </tr>




             </tbody>
           </table>
         </div> -->

          </b-card-text>


        </b-tab>

      </b-tabs>





    </div>

  </div>




</template>