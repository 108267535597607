import { defineStore } from "pinia";
import axios from "axios";
import { useAccountStore } from "./account";
import { usePlayerStore } from "./player";
import { useEventStore } from "./events";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
const toast = useToast();
import moment from "moment";

export const useAdminStore = defineStore({
  id: "admin",

  state: () => ({
    playerStore: usePlayerStore(),
    accountStore: useAccountStore(),
    eventStore: useEventStore(),
    modal: "",
    dateToday: moment().format("YYYY-MM-DD"),

    openEvents: [],
    closedEvents: [],
    initialized: false,
    user: "",

    accounts: [],

    accountsLoaded: false,
    sabongProfit: 0,
    sabongLoss: 0,
    colorGameProfit: 0,
    colorGameLoss: 0,

    userHistory: [],

    userDetails: null,
    blockedAccounts: [],
    preActiveAccounts: [],
    blockedAccountsLoaded: false,
    preActiveAccountsLoaded: false,

    cashInOut: "",

    betHistory: [],

    agents: [],
    players: [],
    summaryReport: {
      data: [],
      meron: 0,
      wala: 0,
      draw: 0,
      agentCommissions: 0,
      siteCommission: 0
    },

    profitData: "",
  }),

  actions: {

    searchBetHistory(referral, date, entries){



      let dateQuery;
      let typeQuery;

      if (date == "All") dateQuery = "from_transaction_date=2023-01-01";
      else dateQuery = "transaction_date=" + date;

    

      console.log(referral, dateQuery, typeQuery, entries)

      axios(
       `/api/admin/event/sabong/bets/?limit=${entries}&${dateQuery}&order_by=-created_at&username=${referral}`
     )
       .then((response) => {
     
        this.betHistory = response.data
 
        console.log(this.betHistory)
       })
       .catch((error) => {
 
         this.referralHistory = "none"
 
 
       });

 
 
   },
    summaryReports(date) {
      this.summaryReport.meron = 0;
      this.summaryReport.wala = 0;
      this.summaryReport.draw = 0;
      this.summaryReport.agentCommissions = 0;
      this.summaryReport.siteCommission = 0;

      
      axios(`/api/admin/event/sabong/bets?transaction_date=${date}`)
        .then((response) => {
          this.summaryReport.data = response.data;

          console.log(response)

          for (let i = 0; i < response.data.results.length; i++) {
            if (response.data.results[i].choice == "meron")
              this.summaryReport.meron += response.data.results[i].amount;
            if (response.data.results[i].choice == "wala")
              this.summaryReport.wala += response.data.results[i].amount;
            if (response.data.results[i].choice == "draw")
              this.summaryReport.draw += response.data.results[i].amount;
          }
        })

        .catch((error) => {});



        axios(`/api/admin/account/history?type=EVC&transact_by_role=player&transact_to_role=player&transaction_date=${date}`)
        .then((response) => {
         
          console.log(response)

          for (let i = 0; i < response.data.results.length; i++) {
       
              this.summaryReport.agentCommissions += Number(response.data.results[i].amount);
           
          }
        })

        .catch((error) => {});

        axios(`/api/admin/account/history?type=EVC&transact_to_role=admin&transaction_date=${date}&limit=100000000`)
        .then((response) => {
         
          console.log(response)

          for (let i = 0; i < response.data.results.length; i++) {
       
              this.summaryReport.siteCommission += Number(response.data.results[i].amount);
           
          }
        })

        .catch((error) => {});


        





    },

    getPlayers() {
      axios("/api/admin/account?is_active=true&commission=0&type=player")
        .then((response) => {
          this.players = response.data;
        })

        .catch((error) => {});
    },

    getAgents() {
      axios("/api/admin/account?is_active=true&commissionGT=0&type=player")
        .then((response) => {
          this.agents = response.data;
        })

        .catch((error) => {});
    },

    transactionAcceptReject(type, command, transactionId) {
      console.log(type, command, transactionId);

      Swal.fire({
        title: `${command} ${type}?`,

        icon: "question",
        toast: true,

        inputValue: 1,
        iconColor: "yellow",
        background: "#2A3042",
        color: "yellow",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios
            .post(`/api/admin/account/${type}/${transactionId}/`, {
              request_status: command,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              let errorMessages = "";

              console.error(errorMessages);

              Swal.showValidationMessage(errorMessages);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `${type} ${command}! `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: `Insufficient Chips!  `,
          });
        }
      });
    },

    getCashInOut(command) {
      let type;

      if (command == "cash-in") type = "CI";
      else if (command == "cash-out") type = "CO";

      axios(`/api/admin/account/history?status=pending&type=${type}`)
        .then((response) => {
          this.cashInOut = response.data;
        })
        .catch((error) => {
          this.cashInOut = "none";
        });
    },

    searchTransaction(user, date, transaction, entries) {
      let dateQuery;
      let typeQuery;

      if (date == "All") dateQuery = "from_transaction_date=2023-01-01";
      else dateQuery = "transaction_date=" + date;

      if (transaction == "All") typeQuery = "";
      else typeQuery = "type=" + transaction;

      console.log(user, dateQuery, typeQuery, entries);

      axios(
        `/api/admin/account/history?limit=${entries}&${typeQuery}&${dateQuery}&order_by=-created_at&username=${user}`
      )
        .then((response) => {
          this.userHistory = response.data;

          console.log(this.userHistory);
        })
        .catch((error) => {
          this.userHistory = "none";
        });
    },
    getPreActiveAccounts() {
      if (!this.preActiveAccountsLoaded) {
        axios("/api/admin/account?status=pre-active")
          .then((response) => {
            for (var i = 0; i < response.data.results.length; i++) {
              this.preActiveAccounts.push(response.data.results[i]);
            }

            this.preActiveAccountsLoaded = true;
          })

          .catch((error) => {});
      }
    },

    getBlockedAccounts() {
      if (!this.blockedAccountsLoaded) {
        axios("/api/admin/account?status=suspended")
          .then((response) => {
            console.log(response);

            for (var i = 0; i < response.data.results.length; i++) {
              this.blockedAccounts.push(response.data.results[i]);
            }

            this.blockedAccountsLoaded = true;
          })

          .catch((error) => {});
      }
    },

    changeUserStatus(username) {
      if (this.userDetails.is_active == true) {
        Swal.fire({
          title: "Deactivate " + username + "?",

          icon: "question",
          toast: true,

          inputValue: 1,
          iconColor: "white",
          background: "#2A3042",
          color: "white",
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios
              .patch(`/api/admin/account/${username}/`, {
                is_active: false,
              })
              .then((response) => {})
              .catch((error) => {
                let errorMessages = "";

                console.error(errorMessages);

                Swal.showValidationMessage(errorMessages);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed && result.value == true) {
            Swal.fire({
              toast: true,
              icon: "success",
              iconColor: "green",
              background: "#2A3042",
              color: "yellow",
              confirmButtonColor: "#34c38f",
              title: `Blocked ${username}! `,
            });
          } else if (result.isConfirmed && result.value == null) {
            Swal.fire({
              toast: true,
              icon: "error",
              iconColor: "red",
              background: "#2A3042",
              color: "red",

              title: `Insufficient Chips!  `,
            });
          }
        });
      } else if (this.userDetails.is_active == false)
        Swal.fire({
          title: "Activate " + username + "?",

          icon: "question",
          toast: true,

          inputValue: 1,
          iconColor: "white",
          background: "#2A3042",
          color: "white",
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios
              .patch(`/api/admin/account/${username}/`, {
                is_active: true,
              })
              .then((response) => {})
              .catch((error) => {
                let errorMessages = "";

                console.error(errorMessages);

                Swal.showValidationMessage(errorMessages);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed && result.value == true) {
            Swal.fire({
              toast: true,
              icon: "success",
              iconColor: "green",
              background: "#2A3042",
              color: "yellow",
              confirmButtonColor: "#34c38f",
              title: `Activated ${username}! `,
            });
          } else if (result.isConfirmed && result.value == null) {
            Swal.fire({
              toast: true,
              icon: "error",
              iconColor: "red",
              background: "#2A3042",
              color: "red",

              title: `Insufficient Chips!  `,
            });
          }
        });
    },

    resetPassword(username) {
      Swal.fire({
        title: "Reset Password of " + username + "?",

        icon: "question",
        toast: true,

        inputValue: 1,
        iconColor: "white",
        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios
            .patch(`/api/account/referrals/${username}`, {
             
              password: username + 123,
            })
            .then((response) => {})
            .catch((error) => {
              let errorMessages = "";
              for (let key in error.response.data) {
                errorMessages += error.response.data[key] + "\n";
              }
              console.error(errorMessages);

              Swal.showValidationMessage(errorMessages);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Updated password of  ${username}! `,
          });
        }
      });
    },
    modalUserDetails(user) {
      this.userDetails = user;
    },
    searchuserHistory(user) {
      axios(`/api/account/referral/${user}/history?limit=100&type=EVB`)
        .then((response) => {
          this.userHistory = response.data;
        })
        .catch((error) => {
          this.userHistory = "none";
        });
    },
    async getProfit(date) {
      this.sabongProfit = 0;
      this.colorGameProfit = 0;
      this.colorGameLoss = 0;

      await axios(
        `/api/account/history?type=EVC&transaction_date=${date}&transact_to=admin&order_by=-created_at`
      )
        .then((response) => {
          for (var i = 0; i < response.data.results.length; i++)
            if (response.data.results[i].note == "sabong") {
              this.sabongProfit += Number(response.data.results[i].amount);
            } else if (response.data.results[i].note == "color-game") {
              this.colorGameProfit += Number(response.data.results[i].amount);
            }
        })
        .catch((error) => {});

      await axios(
        `/api/account/history?type=PR&type=LO&transaction_date=${date}&transact_to=admin&order_by=-created_at`
      )
        .then((response) => {
          for (var i = 0; i < response.data.results.length; i++)
            if (response.data.results[i].note == "color-game") {
              if (response.data.results[i].transactionType == "PR")
                this.colorGameProfit += Number(response.data.results[i].amount);
              else if (response.data.results[i].transactionType == "LO")
                this.colorGameLoss += Number(response.data.results[i].amount);
            }
        })
        .catch((error) => {});

      if (this.sabongProfit + this.colorGameProfit + this.colorGameLoss == 0) {
        this.profitData = false;
      } else this.profitData = true;
    },

    searchAccount(username, role) {

      let query

      if (role == 'agent')
        query = 'commission=0&type=player'
      else if (role =='player')
        query = 'commissionGT=0&type=player'

      axios(`/api/admin/account/${username}`)
        .then((response) => {
          this.user = response.data;

          console.log(this.user);
        })
        .catch((error) => {
          this.user = username;
        });
    },

    eventDetails(event) {
      Swal.fire({
        html: `  
                             
      <table class="table table-responsive mb-0 table-sm table-borderless text-info">
        
          <tbody class="text-info">
  
  
              
          
       

   
 

<tr>
  
<th class="">Type</th>
<td class="">${event.gameType}   </td>

</tr>
<tr>
  
<th class="">Date</th>
<td class="">${this.playerStore.formatDate(event.eventDateCreated_at)}   </td>

</tr>
<tr>
  
<th class="">Plasada</th>
<td class="">${event.plasada}   </td>

</tr>
<tr>
  
<th class="">Min. Bet</th>
<td class="">${event.minimumBet}   </td>

</tr>
<tr>
  
<th class="">Profit</th>
<td class="">${event.profit}   </td>

</tr>
<tr>
  
<th class="">Rounds</th>
<td class="">${event.round_set.length}   </td>

</tr>
<tr>
  
<th class="">Link</th>
<td class="">${event.eventVideoLink}   </td>

</tr>
      
          </tbody>
      </table>
 
      `,

        title: `<span class="text-info">${event.eventName}</span>`,

        background: "#2A3042",
        color: "#6495ED",

        confirmButtonText: "Done",

        showCancelButton: false,
      });
    },

    recode(gameType) {
      switch (gameType) {
        case "Color Game":
          gameType = "color-game";
          return gameType;
          break;

        case "Sabong":
          gameType = "sabong";
          return gameType;
          break;
      }
    },
    async editEvent(event) {
      const awaitSwal = await Swal.fire({
        html: `  <div class="table-responsive table-dark">
                   
    <table class="table mb-0 table-sm table-borderless">
      
        <tbody>


            <tr class="mb-0">

             
                <th scope = "row" class="text-dark">Name</th>

                <td class="text-danger"><input id="name" class="form-control bg-transparent text-dark"  value=${event.eventName} ></td>

            </tr>
            <tr>

            <th scope = "row" class="text-dark">Plasada</th>
            <td class="text-danger"><input type ="number" id="plasada" class="form-control bg-transparent text-dark"  value=${event.plasada}   ></td>


        </tr>
        <tr>

        <th scope = "row" class="text-dark">Minimum Bet</th>
        <td class="text-danger"><input type ="number" id="minimumBet" class="form-control bg-transparent text-dark"  value=${event.minimumBet}  ></td>


    </tr>
        <tr>

        <th scope = "row" class="text-dark">Link</th>
        <td class="text-danger"><input type = "url "id="link" class="form-control bg-transparent text-dark"  value=${event.eventVideoLink} ></td>


    </tr>
        </tbody>
    </table>

</div>
    `,

        title: "Edit Event",

        toast: true,
        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const name = document.getElementById("name").value;
          const plasada = document.getElementById("plasada").value;
          const minimumBet = document.getElementById("minimumBet").value;
          const link = document.getElementById("link").value;

          return axios
            .patch(`/api/admin/event/${event.gameType}/${event.eventId}/`, {
              eventName: name,
              plasada: plasada,
              minimumBet: minimumBet,
              eventVideoLink: link,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              Swal.showValidationMessage(
                `Request failed: ${error.response.data[0]}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `Event Updated! `,
            imageUrl: result.value.avatar_url,
          });
        }
      });
    },

    getPrivateEvents() {
      if (this.initialized == false) {
        axios("api/admin/event/sabong")
          .then((response) => {
            for (var i = 0; i < response.data.results.length; i++) {
              if (response.data.results[i].is_active == true) {
                this.openEvents.push(response.data.results[i]);
              } else this.closedEvents.push(response.data.results[i]);
            }
          })
          .catch((error) => console.log(error));

        axios("api/admin/event/color-game")
          .then((response) => {
            for (var i = 0; i < response.data.results.length; i++) {
              if (response.data.results[i].is_active == true) {
                this.openEvents.push(response.data.results[i]);
              } else this.closedEvents.push(response.data.results[i]);
            }
          })
          .catch((error) => console.log(error));

        this.initialized = true;
      }
    },

    createEvent(
      gameType,
      eventName,
      plasada,
      minimumBet,
      eventDate,
      eventVideoLink
    ) {
      Swal.fire({
        title: "Create Event with plasada of " + plasada + "%?",
        icon: "warning",
        iconColor: "#F46A6A",
        background: "#2A3042",
        color: "white",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          gameType = this.recode(gameType);

          console.log("recoded gameType", gameType);

          axios
            .post("/api/admin/event/" + gameType + "/", {
              gameType: gameType,
              eventName: eventName,
              plasada: plasada,
              minimumBet: minimumBet,
              eventSchedDate: this.dateToday,
              // eventSchedDate: this.eventDate,
              eventVideoLink: eventVideoLink,
              is_active: true,
            })
            .then((response) => {
              toast.info(gameType + " event Created!", {
                position: "top-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: "fa fa-solid fa-trophy",
                rtl: false,
              });

              this.openEvents.push(response.data);
              this.eventStore.publicEvents.push(response.data);

              axios
                .post(
                  "/api/admin/event/" +
                    gameType +
                    "/" +
                    response.data.eventId +
                    "/round/",
                  {
                    roundEvent: response.data.eventId,
                    operator: this.accountStore.username,
                    roundNumber: 1,
                    status: "closed",
                  }
                )
                .then((response) => {})
                .catch((error) => console.log(error.data));
            });
        }
      });
    },

    closeEvent(eventId, gameType) {
      Swal.fire({
        title: "Close Event?",
        toast: true,
        icon: "warning",
        iconColor: "#F46A6A",
        background: "#2A3042",
        color: "white",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          axios
            .patch("/api/admin/event/" + gameType + "/" + eventId + "/", {
              is_active: false,
            })
            .then((response) => {
              toast.info("Round closed!", {
                position: "top-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: "fa fa-solid fa-trophy",
                rtl: false,
              });

              {
                for (let i = 0; i < this.openEvents.length; i++) {
                  if (this.openEvents[i].eventId == eventId) {
                    this.closedEvents.push(this.openEvents[i]);
                    this.openEvents.splice(i, 1);
                    this.eventStore.publicEvents.splice(i, 1);

                    console.log("event spliced.");
                  }
                }
              }
            });
        }
      });

      // axios.patch('/api/admin/event/' + eventId + '/', {

      //   is_active : false

      // })
      // .then(response => Swal.fire("EVENT CLOSED."))
      // .catch(error => console.log(error))
    },
  },
});
