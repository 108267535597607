<script>

import { SimpleBar } from 'simplebar-vue3';
import axios from 'axios';
import { date } from 'quasar';
import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import { useAdminStore } from '../../stores/admin';
import { useAgentStore } from '../../stores/agent';


export default {

  components: { SimpleBar },



  data() {

    return {


      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),
      adminStore: useAdminStore(),
      agentStore: useAgentStore(),

      username: '',
      referral: '',
      date: "All",
      transaction: 'All',
      entries: 10,
      gameType: 'sabong',

      username: '',

      sendchips: 0,

      user: '',
      testModal: false




    };


  },

  computed: {




  },

  mounted() {

    this.agentStore.getPreActiveAccounts()

  }

}


</script>


<template>

  <b-modal v-if="adminStore.userDetails" v-model="testModal" id="modal-center" centered title="User Details"
    title-class="font-18" hide-footer>
    <h5 class="text-dark" v-if="adminStore.userDetails">

      <table class="table table-striped text-dark align-middle table-sm text-center table-responsive font-size-14 ">


        <tbody>



          <tr>
            <th>Email</th>
            <td>{{ adminStore.userDetails.email }}</td>
          </tr>
          <tr>
            <th>Cell#</th>
            <td> {{ adminStore.userDetails.cellphoneNumber }}</td>

          </tr>
          <tr>
            <th>Date joined</th>
            <td>{{ this.playerStore.formatDate(adminStore.userDetails.date_joined) }}</td>
          </tr>
          <tr>
            <th>Last login</th>
            <td>{{ this.playerStore.formatDate(adminStore.userDetails.last_login) }}</td>
          </tr>
          <tr>
            <th>Referred by</th>
            <td><button v-if="adminStore.userDetails.referred_by" class="btn btn-info" type="button"
                @click="adminStore.modalUserDetails(adminStore.userDetails.referred_by)"> {{
                  adminStore.userDetails.referred_by
                }}</button></td>
          </tr>
          <tr>
            <th>Referrals</th>
            <td>
              <p style="float: left" class="mr-1" v-for="referral in adminStore.userDetails.referrals">
                <button class="btn btn-info" type="button" @click="adminStore.modalUserDetails(referral)">
                  {{ referral.username }}</button>
              </p>
            </td>
          </tr>
        </tbody>
      </table>




      <button class="btn btn-info" type="button" @click="adminStore.resetPassword(adminStore.userDetails.username)">
        Reset Password</button>
      <button v-if="adminStore.userDetails.is_active == true" class="btn btn-danger" type="button"
        @click="agentStore.changeUserStatus(adminStore.userDetails, 'deny')"> Deactivate User</button>
      <button v-if="adminStore.userDetails.is_active == false" class="btn btn-success" type="button"
        @click="agentStore.changeUserStatus(adminStore.userDetails, 'allow')"> Activate User</button>




    </h5>
  </b-modal>


  <div class="card no-footer" style="min-height:460px">
    <div class="card-body">
      <h4 class="card-title mb-4">Pending</h4>

      <b-tabs pills nav-class="bg-light rounded" content-class="mt-3">

        <b-tab title="Users" @click="agentStore.getPreActiveAccounts()">

          <div class="input-group mb-4">

            <input type="text" v-model="username" class="form-control" placeholder="Search username" />
            <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
              :disabled="!username">Search</button>
          </div>





          <b-card-text>

            <div class="">
              <SimpleBar style="max-height: 400px;">
                <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
                  v-if="adminStore.user != 'null'">
                  <thead>
                    <tr class="font-size-13 text-center">
                      <th class="text-info"> Username</th>


                      <th class="text-warning">Referred by</th>
                      <th class="text-success">Allow</th>

                      <th class="text-danger">Deny</th>
                      <!-- <th class="text-pink">Others</th> -->



                    </tr>
                  </thead>
                  <tbody>


                    <tr v-for="account in agentStore.preActiveAccounts" class="font-size-13 text-center"
                      v-if="adminStore.user == ''">



                      <td>
                        <button class="btn btn-outline-info" type="button"
                          @click="adminStore.modalUserDetails(account); testModal = true"> {{
                            account.username
                          }}</button>

                      </td>







                      <td class="text-warning">

                        {{ account.referred_by }}

                      </td>

                      <td>

                        <button class="btn btn-outline-success" type="button"
                          @click="agentStore.changeUserStatus(account, 'allow')"> Allow </button>


                      </td>


                      <td>

                        <button class="btn btn-outline-danger" type="button"
                          @click="agentStore.changeUserStatus(account, 'deny')"> Deny</button>

                      </td>



                      <!-- <td>
                     <button class="btn btn-outline-pink" type="button">Details</button>
                   </td> -->



                    </tr>

                    <tr v-else>
                      <div>
                        <td>
                          <button class="btn btn-outline-info" type="button"
                            @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                              adminStore.user.username
                            }}</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-success" type="button"
                            @click="accountStore.setCommission(adminStore.user.username)"> {{
                              adminStore.user.commission
                            }} %</button>


                        </td>




                        <td>

                          <button class="btn btn-outline-warning" type="button"
                            @click="accountStore.sendChips(adminStore.user.username)"> Send</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-danger" type="button"
                            @click="accountStore.cashOut(adminStore.user.username)"> CO</button>

                        </td>



                      </div>


                    </tr>
                  </tbody>
                </table>

                <div class="text-center" v-else>

                  Account not found.

                </div>
              </SimpleBar>
            </div>


          </b-card-text>


        </b-tab>

        <b-tab class="" title="Cash-In"  @click="agentStore.getCashInOut('cash-in')">

          <div class="input-group mb-4">

            <input type="text" v-model="username" class="form-control" placeholder="Search username" />
            <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
              :disabled="!username">Search</button>
          </div>





          <b-card-text>

            <div class="">
              <SimpleBar style="max-height: 400px;">
                <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
                  v-if="adminStore.user != 'null'">
                  <thead>
                    <tr class="font-size-13 text-center">
                      <th class="text-info"> Username</th>

                      <th class="text-warning">Amount</th>

                      <th class="">Time</th>


                    
                      <th class="text-success">Accept</th>

                      <th class="text-danger">Reject</th>
                      <!-- <th class="text-pink">Others</th> -->



                    </tr>
                  </thead>
                  <tbody>


                    <tr v-for="account in agentStore.cashInOut.results" class="font-size-13 text-center"
                      v-if="adminStore.user == ''">



                      <td class="text-info">
                      
                         {{ account.initiator }}

                      </td>

              


                      <td class="text-warning">
                      
                      {{ account.amount }}

                   </td>


                   <td class="">
                      
                      {{ playerStore.formatDate(account.created_at) }}

                   </td>




                  

                      <td>

                        <button class="btn btn-outline-success" type="button"
                          @click="agentStore.transactionAcceptReject('cashin', 'accept', account.transactionId)"> Accept </button>


                      </td>


                      <td>

                        <button class="btn btn-outline-danger" type="button"
                        @click="agentStore.transactionAcceptReject('cashin', 'decline', account.transactionId)"> Reject</button>

                      </td>






                    </tr>

                    <tr v-else>
                      <div>
                        <td>
                          <button class="btn btn-outline-info" type="button"
                            @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                              adminStore.user.username
                            }}</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-success" type="button"
                            @click="accountStore.setCommission(adminStore.user.username)"> {{
                              adminStore.user.commission
                            }} %</button>


                        </td>




                        <td>

                          <button class="btn btn-outline-warning" type="button"
                            @click="accountStore.sendChips(adminStore.user.username)"> Send</button>

                        </td>

                        <td>

                          <button class="btn btn-outline-danger" type="button"
                            @click="accountStore.cashOut(adminStore.user.username)"> CO</button>

                        </td>



                      </div>


                    </tr>
                  </tbody>
                </table>

                <div class="text-center" v-else>

                  Account not found.

                </div>
              </SimpleBar>
            </div>

          </b-card-text>


        </b-tab>
        <b-tab class="" title="Cash-Out"  @click="agentStore.getCashInOut('cash-out')">

<div class="input-group mb-4">

  <input type="text" v-model="username" class="form-control" placeholder="Search username" />
  <button class="btn btn-info" type="button" @click="adminStore.searchAccount(username)"
    :disabled="!username">Search</button>
</div>





<b-card-text>

  <div class="">
    <SimpleBar style="max-height: 400px;">
      <table class="table table-responsive  table-wrap text-center align-middle mb-0 table-sm"
        v-if="adminStore.user != 'null'">
        <thead>
          <tr class="font-size-13 text-center">
            <th class="text-info"> Username</th>

            <th class="text-warning">Amount</th>

            <th class="">Time</th>
          
            <th class="text-success">Accept</th>

            <th class="text-danger">Reject</th>
            <!-- <th class="text-pink">Others</th> -->



          </tr>
        </thead>
        <tbody>


          <tr v-for="account in agentStore.cashInOut.results" class="font-size-13 text-center"
            v-if="adminStore.user == ''">



            <td class="text-info">
            
               {{ account.initiator }}

            </td>


            <td class="text-warning">
            
            {{ account.amount }}

         </td>


         <td class="">
                      
                      {{ playerStore.formatDate(account.created_at) }}

                   </td>








        

            <td>

              <button class="btn btn-outline-success" type="button"
                @click="agentStore.transactionAcceptReject('cashout', 'accept', account.transactionId)"> Accept </button>


            </td>


            <td>

              <button class="btn btn-outline-danger" type="button"
              @click="agentStore.transactionAcceptReject('cashout', 'decline', account.transactionId)"> Reject</button>

            </td>






          </tr>

          <tr v-else>
            <div>
              <td>
                <button class="btn btn-outline-info" type="button"
                  @click="adminStore.modalUserDetails(adminStore.user); testModal = true"> {{
                    adminStore.user.username
                  }}</button>

              </td>

              <td>

                <button class="btn btn-outline-success" type="button"
                  @click="accountStore.setCommission(adminStore.user.username)"> {{
                    adminStore.user.commission
                  }} %</button>


              </td>




              <td>

                <button class="btn btn-outline-warning" type="button"
                  @click="accountStore.sendChips(adminStore.user.username)"> Send</button>

              </td>

              <td>

                <button class="btn btn-outline-danger" type="button"
                  @click="accountStore.cashOut(adminStore.user.username)"> CO</button>

              </td>



            </div>


          </tr>
        </tbody>
      </table>

      <div class="text-center" v-else>

        Account not found.

      </div>
    </SimpleBar>
  </div>

</b-card-text>


</b-tab>









      </b-tabs>





    </div>

  </div>




</template>