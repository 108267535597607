import { defineStore } from "pinia";
import { useAccountStore } from "./account";
import { useEventStore } from "/src/stores/events";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";



export const useAgentStore = defineStore({
  id: "agent",

  state: () => ({
  
    accountStore: useAccountStore(),
    eventStore: useEventStore(),
    referralHistory: '',
    preActiveAccounts: [],
    preActiveAccountsLoaded: false,
    cashInOut: [],
    agents: [],
    players: [],
    blocked: []

   

   
  }),
   

  actions: {
    convertToChips(username) {
    
      Swal.fire({
        title: `How much commission to withdraw?`,

        icon: "question",
       
        html: `
        
        <input type="number" id="amount" class="form-control" min='0' value = 0 oninput="this.value = 
        !!this.value && Math.abs(this.value) >= 0  ? Math.abs(this.value) : null"/>
        
        
        
        
        
        
        `,
        inputValue: 0,
        iconColor: "green",
        background: "#2A3042",
        color: "green",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let amount = document.getElementById("amount").value;
       

           
            return axios
              .post(`/api/account/convertToChips/`, {
                amount: amount,
                to: username
                
              
              })
              .then((response) => {console.log(response)})
              .catch((error) => {
               
            
              
                Swal.showValidationMessage(error.response.data);
              });
       

          
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
          let amount = document.getElementById("amount").value;

          
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `You have converted ${Number(amount).toLocaleString()} commission `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",

            title: ` Not enough Commision!  `,
          });
        }
      });


  

    },

    getBlocked() {
      axios("/api/account/referrals?is_active=false")
      .then((response) => {
      
       
           this.blocked = response.data
      })

      .catch((error) => {
      
      });
    },

    linkCopied(){

      Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'Link Copied!',
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        background: "#2A3042",
        color: "yellow",
        
      })





    },
    getPlayers() {
     
         axios("/api/account/referrals?is_active=true&commission=0")
          .then((response) => {
          
           
               this.players = response.data
          })
  
          .catch((error) => {
          
          });
      
    },

    getAgents() {
     
         axios("/api/account/referrals?is_active=true&commissionGT=0")
          .then((response) => {
          
           
            this.agents = response.data
          })
  
          .catch((error) => {
          
          });
      
    },

    transactionAcceptReject(type, command, transactionId) {

    
  

      console.log(type, command, transactionId)

       
      Swal.fire({
        title: `${command} ${type}?`,
  
        icon: "question",
        toast: true,
       
        inputValue: 1,
        iconColor: "yellow",
        background: "#2A3042",
        color: "yellow",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
         
  
            return axios
              .post(`/api/admin/account/${type}/${transactionId}/` , {
  
                request_status: command
              
              })
              .then((response) => {
                console.log(response)
               
              })
              .catch((error) => {
              
               
                console.log(error.response.data);
  
                Swal.showValidationMessage(error.response.data.account);
              });
            
                    
    
        
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
  
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `${type} ${command}! `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",
  
            title: `Insufficient Chips!  `,
          });
        }
      });
  
     
    },
    getCashInOut(command){

      let type
      let axiosLink

      // if (this.accountStore.role == 'admin')
      // axiosLink = "/api/admin/account/history?status=pending&type="
      // else
      axiosLink = "/api/account/referrals/history?status=pending&type="
  
      if (command =="cash-in")
      type = "CI"
  
      else if (command =="cash-out")
      type = "CO"
      
      console.log( `${axiosLink}${type}`)
  
      axios(
       `${axiosLink}${type}`
     )
       .then((response) => {
     
        this.cashInOut = response.data

        console.log(response.data)
  
       
       })
       .catch((error) => {
  
         this.cashInOut = "none"
  
  
       });
  
  
  
   },
    getPreActiveAccounts(){

      let axiosLink

      // if (this.accountStore.role =='admin')
      // axiosLink = "/api/admin/account?status=pre-active"
      // else 
      axiosLink = "/api/account/referrals?status=pre-active"
      if (!this.preActiveAccountsLoaded) {
        axios(axiosLink)
         .then((response) => {

          
  
       
         
          
           for (var i = 0; i < response.data.results.length; i++) {
             this.preActiveAccounts.push(response.data.results[i]);
           }
  
  
           this.preActiveAccountsLoaded = true;
         })
  
         .catch((error) => {
         
         });
     }
  
  
  
  
    },
    searchBetHistory(referral, date, transaction, entries){



      let dateQuery;
      let typeQuery;

      if (date == "All") dateQuery = "from_transaction_date=2023-01-01";
      else dateQuery = "transaction_date=" + date;

      if (transaction == "All") typeQuery = "";
      else typeQuery = "type=" + transaction;

      console.log(referral, dateQuery, typeQuery, entries)

      axios(
       `/api/event/sabong/bets/referrals?limit=${entries}&${typeQuery}&${dateQuery}&order_by=-created_at&username=${referral}`
     )
       .then((response) => {
     
        this.referralHistory = response.data
 
        console.log(this.referralHistory)
       })
       .catch((error) => {
 
         this.referralHistory = "none"
 
 
       });

 
 
   },
   

    changeUserStatus(user, command) {

      let axiosLink

      if (this.accountStore.role =='admin')
      axiosLink = "/api/admin/account"
      else 
      axiosLink = "/api/account/referrals"

      console.log(user)
      if(command == 'allow'){
      Swal.fire({
        title: "Allow " + user.username + "?",
  
        icon: "question",
        toast: true,
       
        inputValue: 1,
        iconColor: "white",
        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
         
       
            return axios
              .patch(`${axiosLink}/${user.username}/` , {
                is_active: true,
                account_status: "active"
      
              })
              .then((response) => {
               
              })
              .catch((error) => {
                let errorMessages = "";
               
                console.error(errorMessages);
  
                Swal.showValidationMessage(errorMessages);
              });
        
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
  
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `${user.username} allowed!`,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",
  
            title: `Insufficient Chips!  `,
          });
        }
      });}
  
      else if (command == 'deny')
      Swal.fire({
        title: "Deny " + user.username + "?",
  
        icon: "question",
        toast: true,
       
        inputValue: 1,
        iconColor: "white",
        background: "#2A3042",
        color: "white",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
         
       
            return axios
              .patch(`/api/admin/account/${user.username}/` , {
                is_active: false,
                account_status: "suspended"
      
              })
              .then((response) => {
               
              })
              .catch((error) => {
                let errorMessages = "";
               
                console.error(errorMessages);
  
                Swal.showValidationMessage(errorMessages);
              });
        
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value == true) {
  
          Swal.fire({
            toast: true,
            icon: "success",
            iconColor: "green",
            background: "#2A3042",
            color: "yellow",
            confirmButtonColor: "#34c38f",
            title: `${user.username} suspended! `,
          });
        } else if (result.isConfirmed && result.value == null) {
          Swal.fire({
            toast: true,
            icon: "error",
            iconColor: "red",
            background: "#2A3042",
            color: "red",
  
            title: `Error! `,
          });
        }
      });
    },
    
    getBets(eventId, gameType, latestround) {
      axios("/api/event/" + gameType + "/" + eventId + "/bets/me/").then(
        (response) => {
          this.betHistory = response.data;

          switch (gameType) {
            case "sabong":
              for (var i = 0; i < response.data.length; i++) {
                if (response.data[i].roundNumber == latestround.roundNumber) {
                  if (response.data[i].choice == "meron") {
                    this.meronBet += response.data[i].amount;
                  } else if (response.data[i].choice == "wala") {
                    this.walaBet += response.data[i].amount;
                  }
                }
              }

              this.playerStore.computeWinnings(latestround);

              break;

            case "color-game":
              for (var i = 0; i < response.data.length; i++) {
  
                if (response.data[i].roundNumber == latestround.roundNumber) {
                  this.colorGameStore.bets.red = response.data[i].red;
                  this.colorGameStore.bets.green = response.data[i].green;
         
                  this.colorGameStore.bets.blue = response.data[i].blue;
                  this.colorGameStore.bets.yellow = response.data[i].yellow;
                  this.colorGameStore.bets.white = response.data[i].white;
                  this.colorGameStore.bets.pink = response.data[i].pink;
                }
               
              }

             

           

              break;
          }
        }
      );
    },

    async nextReferralHistory(axiosLink){


  
     
      let response = await axios(axiosLink).then((response) =>{
 
       this.referralHistory = response.data
  
         console.log(this.referralHistory)
    
       })
       .catch((error) => {
         
         this.referralHistory = "none"
      
       });
 
         
 
     },

    searchTransaction(referral, date, transaction, entries){



      let dateQuery;
      let typeQuery;

      if (date == "All") dateQuery = "from_transaction_date=2023-01-01";
      else dateQuery = "transaction_date=" + date;

      if (transaction == "All") typeQuery = "";
      else typeQuery = "type=" + transaction;

      console.log(referral, dateQuery, typeQuery, entries)

      axios(
       `/api/account/referrals/history?limit=${entries}&${typeQuery}&${dateQuery}&order_by=-created_at&username=${referral}`
     )
       .then((response) => {
     
        this.referralHistory = response.data
 
        console.log(this.referralHistory)
       })
       .catch((error) => {
 
         this.referralHistory = "none"
 
 
       });

 
 
   },
   
  
   
  }
});
