<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import FM from "/src/components/chips/chips-management";
import Transactions from "/src/components/chips/transactions.vue";
import events from "../../../components/playerUI/events.vue";
import EM from "../../../components/admin/events-management.vue";
import AP from "/src/components/admin/referrals.vue";
import { useEventStore } from "../../../stores/events";
import { useAccountStore } from "../../../stores/account";
import { useAdminStore } from "../../../stores/admin";
import { usePlayerStore } from "../../../stores/player";
import dR from "../../../components/management/profitandloss.vue";
import masterList from "../../../components/admin/masterList.vue";
import transactionSearch from "../../../components/admin/transactionSearch.vue";
import pending from "../../../components/admin/pending.vue";

import sfx from "/src/assets/sounds/fx1.wav"
var fxplay = new Audio(sfx)

import homeuserinfo from "../../../components/admin/homeuserinfo.vue";





/**
 * Starter component
 */
export default {
  page: {
    title: "Starter Page",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, FM, Transactions, events, EM, AP, homeuserinfo, dR, masterList, transactionSearch, pending },
  data() {
    return {
      eventStore: useEventStore(),
      adminStore: useAdminStore(),
      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),


    };
  },


  mounted() {

    if(this.accountStore.role == 'admin' || this.accountStore.role == 'operator'){
    this.adminStore.getPrivateEvents()

    }
  // this.playsfx()




  },

  methods: {

    playsfx() {
      fxplay.play()
    },

  }


};
</script>
<template>
  <Layout>
   
    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab title="Home" class="font-size-12">


        <div class="row">
      <div class="col-lg-4 mb-3">

        <events></events>
      </div>
      
      <div class="col-lg-8">

        <div class="container">

          <div class="row">

            <div class="col-lg-6">

         <homeuserinfo></homeuserinfo>

</div>


            

            <div class="col-lg-6">


              <Transactions :transactionsData="transactionsData" />
            </div>

           

           
            <!-- <div class="col-lg-12" v-if="accountStore.role == 'admin' || accountStore.role == 'operator'">
              <transactionSearch></transactionSearch>
 </div> -->

         

         
          </div>
        </div>
      </div>
    </div>

</b-tab>

<b-tab title="Pending" class="font-size-12" v-if="accountStore.commission > 0">


<div class="row">


<div class="col-lg-12">

<div class="row">

  
  <div class="col-lg-12">
      <pending></pending>
    </div>
   


  

  

    
  
</div>
</div>
</div>

</b-tab>


<b-tab title="Users" class="font-size-12" v-if="accountStore.commission > 0 || accountStore.role =='loader'">


<div class="row">


<div class="col-lg-12">

<div class="row">

  
 


  

  
    <!-- <div class="col-lg-12" v-if="accountStore.role == 'admin' || accountStore.role == 'operator'">
      <transactionSearch></transactionSearch>
</div> -->

 

 
    <div class="col-lg-12" v-if="accountStore.commission > 0">
      <AP></AP>
    </div>

    <div class="col-lg-12" v-if="accountStore.role == 'admin' || accountStore.role == 'loader' || accountStore.role=='superadmin'">
      <masterList></masterList>
    </div>
   
    
  
</div>
</div>
</div>

</b-tab>


<b-tab title="Events" class="font-size-12" v-if="accountStore.role == 'admin' || accountStore.role == 'operator' || accountStore.role=='superadmin'">


<div class="row">


<div class="col-lg-12">

<div class="row">

  
 


    <div class="col-lg-12" v-if="accountStore.role == 'admin' || accountStore.role=='superadmin'">

<dR></dR>

</div>

    <div class="col-lg-6" v-if="accountStore.role == 'admin' || accountStore.role == 'operator' || accountStore.role =='superadmin'">
      <EM></EM>
    </div>
    <!-- <div class="col-lg-12" v-if="accountStore.role == 'admin' || accountStore.role == 'operator'">
      <transactionSearch></transactionSearch>
</div> -->

 

 
  
</div>
</div>
</div>

</b-tab>




   


    </b-tabs>





  </Layout>
</template>