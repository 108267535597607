<script>

import { SimpleBar } from 'simplebar-vue3';
import axios from 'axios';
import { date } from 'quasar';
import { useAccountStore } from '/src/stores/account'
import { usePlayerStore } from '../../stores/player';
import { useAdminStore } from '../../stores/admin';
import { useAgentStore } from '../../stores/agent';
import moment from 'moment';


export default {

  components: { SimpleBar },



  data() {

    return {


      accountStore: useAccountStore(),
      playerStore: usePlayerStore(),
      adminStore: useAdminStore(),
      agentStore: useAgentStore(),

      username: '',
      referral: '',
      date: "All",
      transaction: 'All',
      entries: 10,
      gameType: 'sabong',

 

      user: '',





    };


  },

  computed: {




  },

  mounted() {

  
  }

}


</script>


<template>



  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-3">Transactions</h4>
      <b-tabs pills justified content-class="p-3 text-muted">
        
        <!-- <b-tab title="Bets" active>
          <form>
            <div class="row"> <div class="col-4">
              <div class="form-group mt-3 mb-0">
                <label>Username</label>
<input type="text" v-model="referral" class="form-control" placeholder="Search" />

</div>
</div>
              <div class="col-4">
                <div class="form-group mt-3 mb-0">

                  <label>Date</label>
                  <input type="date" v-model="date" data-provide="datepicker" class="form-control" />

                </div>
              </div>

              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Game Type</label>
                  <select class="form-control"  v-model="gameType">
                    <option value="sabong" selected>Sabong</option>
        
                    <option value="color-game">Color Game</option>
                  </select>
                </div>
              </div>



              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Show # of entries</label>
                  <select class="form-control" v-model="entries">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div class="col-xl col-sm-6 align-self-end">
                <div class="mt-3">
                  <button class="btn btn-primary w-md" type="button" @click="agentStore.getBets(referral, date, gameType, entries)"
  :disabled="!referral">Search</button>
                </div>
              </div>
            </div>
          </form>
        

          <SimpleBar style="max-height: 300px">
          <div class="table-responsive mt-3 mb-0">
            <table class="table table-centered align-middle font-size-14"   v-if="agentStore.referralHistory.count != 0" >
                <thead  class="text-center">

                    <tr>
                      <td>Type</td>
                      <td>Created at</td>
                      <td>Amount</td>
                      <td>From</td>
                      <td>To</td>
                     
                    </tr>
                </thead>
            <tbody v-for="transaction of agentStore.referralHistory.results" class="text-center">
              <tr>
            <td>{{ transaction.transactionType }}</td>
            <td>{{ playerStore.formatDate(transaction.created_at) }}</td>
            <td>{{ Number(transaction.amount).toLocaleString() }}</td>
            <td>{{ transaction.transactionFrom }}</td>
            <td>{{ transaction.transactionTo }}</td>
          </tr>

            </tbody>
            </table>

            <div class="text-center" v-else>

No Data found 

</div>
          </div>
          </SimpleBar>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-sm justify-content-end mt-1" role="menubar" aria-disabled="false"
              aria-label="Pagination">
               
         
              <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark" aria-label="Go to next page" aria-controls="my-table"
                  role="menuitem" type="button" tabindex="-1" :disabled="agentStore.referralHistory.previous==null" @click="agentStore.nextReferralHistory(agentStore.referralHistory.previous)">Previous</b-button></li>
              <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark" aria-label="Go to last page" aria-controls="my-table"
                  role="menuitem" type="button" tabindex="-1" :disabled="agentStore.referralHistory.next==null" @click="agentStore.nextReferralHistory(agentStore.referralHistory.next)">Next</b-button></li>
           
                </ul>
              </div>
            </div>
          </div>
        </b-tab> -->
        <b-tab title="Search">
          <form>
            <div class="row"> <div class="col-4">
              <div class="form-group mt-3 mb-0">
                <label>Username</label>
<input type="text" v-model="referral" class="form-control" placeholder="Search" />

</div>
</div>
              <div class="col-4">
                <div class="form-group mt-3 mb-0">

                  <label>Date</label>
                  <input type="date" v-model="date" data-provide="datepicker" class="form-control" />

                </div>
              </div>

              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Transaction</label>
                  <select class="form-control"  v-model="transaction">
                    <option value="All" selected>All</option>
                    <option value="EVB">Bets</option>
                    <option value="CI">Cash-In</option>
                    <option value="CO">Cash-Out</option>
                    <option value="EVC">Commission</option>
                    <option value="S">Sent</option>
                    <option value="R">Received</option>
                    <option value="EVW">Win</option>
                    <option value="EVR">Refund</option>
                  </select>
                </div>
              </div>



              <div class="col-4">
                <div class="form-group mt-3 mb-0">
                  <label>Show # of entries</label>
                  <select class="form-control" v-model="entries">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div class="col-xl col-sm-6 align-self-end">
                <div class="mt-3">
                  <button class="btn btn-primary w-md" type="button" @click="agentStore.searchTransaction(referral, date, transaction, entries)"
  :disabled="!referral">Search</button>
                </div>
              </div>
            </div>
          </form>
        
          <!-- Table -->
          <SimpleBar style="max-height: 300px">
          <div class="table-responsive mt-3 mb-0">
            <table class="table table-centered align-middle font-size-14"   v-if="agentStore.referralHistory.count != 0" >
                <thead  class="text-center">

                    <tr>
                      <td>Type</td>
                      <td>Created at</td>
                      <td>Amount</td>
                      <td>From</td>
                      <td>To</td>
                     
                    </tr>
                </thead>
            <tbody v-for="transaction of agentStore.referralHistory.results" class="text-center">
              <tr>
            <td>{{ transaction.transactionType }}</td>
            <td>{{ playerStore.formatDate(transaction.created_at) }}</td>
            <td>{{ Number(transaction.amount).toLocaleString() }}</td>
            <td>{{ transaction.transactionFrom }}</td>
            <td>{{ transaction.transactionTo }}</td>
          </tr>

            </tbody>
            </table>

            <div class="text-center" v-else>

No Data found 

</div>
          </div>
          </SimpleBar>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-sm justify-content-end mt-1" role="menubar" aria-disabled="false"
              aria-label="Pagination">
               
         
              <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark" aria-label="Go to next page" aria-controls="my-table"
                  role="menuitem" type="button" tabindex="-1" :disabled="agentStore.referralHistory.previous==null" @click="agentStore.nextReferralHistory(agentStore.referralHistory.previous)">Previous</b-button></li>
              <li class="page-item"><b-button class="page-link text-dark" variant="outline-dark" aria-label="Go to last page" aria-controls="my-table"
                  role="menuitem" type="button" tabindex="-1" :disabled="agentStore.referralHistory.next==null" @click="agentStore.nextReferralHistory(agentStore.referralHistory.next)">Next</b-button></li>
           
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
     
      </b-tabs>
    </div>
  </div>


</template>